// extracted by mini-css-extract-plugin
export var column = "ResourceNew__column___4UJ5";
export var container = "ResourceNew__container__Ibf0U";
export var flex = "ResourceNew__flex__Zo87I";
export var flexColumn = "ResourceNew__flexColumn__STTST";
export var gap1 = "ResourceNew__gap1__Wm0Dw";
export var gap2 = "ResourceNew__gap2__SYknL";
export var gap3 = "ResourceNew__gap3__xFAqB";
export var gap4 = "ResourceNew__gap4__CKyJU";
export var gap5 = "ResourceNew__gap5__vcUlz";
export var pageTitle = "ResourceNew__pageTitle__ipH2y";
export var row = "ResourceNew__row__ZNjJp";